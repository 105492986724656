import Card from "react-bootstrap/Card";
import { FeatureLike } from "ol/Feature";
import styles from "./FeatureCard.module.css";

interface FeatureProps {
  feature: FeatureLike;
}

const FeatureCard = (props: FeatureProps) => {
  const name = props.feature.get("name");
  const description = props.feature.get("description");

  return (
    <Card className={styles.featureCard}>
      {name ? <Card.Header>{name}</Card.Header> : null}
      <Card.Body
        dangerouslySetInnerHTML={{ __html: description }}
        className={styles.featureCardBody}
      ></Card.Body>
    </Card>
  );
};

export default FeatureCard;
