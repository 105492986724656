import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { useAppSelector } from "../../store/hooks";
import { shuffle } from "../../utils/array";
import styles from "./SponsorsBar.module.css";

const SponsorsBar = () => {
  const sponsors = useAppSelector((state) => state.sponsors.items);

  function onImageClick(url: string) {
    window.open(url, "_blank")?.focus();
  }

  return (
    <Container fluid>
      <Row>
        {shuffle([...sponsors])
          .filter((sponsor) => sponsor.image)
          .map((sponsor) => (
            <Col>
              <Image
                className={styles.thumbnail}
                src={sponsor.image}
                alt={sponsor.name}
                onClick={(e) => onImageClick(sponsor.url)}
                thumbnail
              />
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default SponsorsBar;
