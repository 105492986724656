import Link from "./Link";
import Widget from "./Widget";
import { useAppSelector } from "../../store/hooks";
// FIXME: move shuffle to store
import { shuffle } from "../../utils/array";

const SponsorsWidget = () => {
  const sponsors = useAppSelector((state) => state.sponsors.items);

  return (
    <Widget title="Sponsors">
      {shuffle([...sponsors]).map((sponsor) => (
        <Link url={sponsor.url} target="_blank" rel="sponsored">
          {sponsor.name}
        </Link>
      ))}
    </Widget>
  );
};

export default SponsorsWidget;
