export function parseDateFromString(value: string) {
  return new Date(value);
}

export function convertDateToString(value: Date) {
  let dateStr = value.toLocaleString("nl-NL");
  return dateStr.substring(0, dateStr.length - 3);
}

export function formatDate(value: string) {
  return convertDateToString(parseDateFromString(value));
}
