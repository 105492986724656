import { useContext, useEffect, useState } from "react";
import KML from "ol/format/KML";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import MapBrowserEvent from "ol/MapBrowserEvent";
import { Coordinate } from "ol/coordinate";
import { FeatureLike } from "ol/Feature";
import MapContext from "../Map/MapContext";
import FeaturesOverlay from "../Overlays/FeaturesOverlay";

interface KMLLayerProps {
  url: string;
}

const KMLLayer = (props: KMLLayerProps) => {
  const { map } = useContext(MapContext);

  const [coordinate, setCoordinate] = useState<Coordinate>();
  const [features, setFeatures] = useState<FeatureLike[]>();

  useEffect(() => {
    if (!map) return;

    const layer = new VectorLayer({
      source: new VectorSource({
        format: new KML(),
        url: props.url,
      }),
      zIndex: 3,
    });

    const mapClickListener = (event: MapBrowserEvent<any>) => {
      setCoordinate(event.coordinate);
      setFeatures(map.getFeaturesAtPixel(event.pixel));
    };

    map.addLayer(layer);
    map.on("click", mapClickListener);

    return () => {
      map.un("click", mapClickListener);
      map.removeLayer(layer);
    };
  }, [map, props.url]);

  return <FeaturesOverlay coordinate={coordinate} features={features} />;
};

export default KMLLayer;
