import { useCallback, useContext, useEffect, useState } from "react";
import Overlay from "ol/Overlay";
import { Coordinate } from "ol/coordinate";
import { FeatureLike } from "ol/Feature";
import MapContext from "../Map/MapContext";
import FeatureCard from "./FeatureCard";
import styles from "./FeaturesOverlay.module.css";

interface FeaturesAtPixelOverlayProps {
  coordinate: Coordinate | undefined;
  features: FeatureLike[] | undefined;
}

const FeaturesOverlay = (props: FeaturesAtPixelOverlayProps) => {
  const { map } = useContext(MapContext);
  const [overlay, setOverlay] = useState<Overlay | undefined>(undefined);

  // initialize the overlay once the DOM is ready
  const targetRef = useCallback((node: any) => {
    if (!node) return;

    setOverlay(new Overlay({ element: node }));
  }, []);

  // add the overlay to the map
  useEffect(() => {
    if (!map || !overlay) return;

    map.addOverlay(overlay);

    return () => {
      map.removeOverlay(overlay);
    };
  }, [map, overlay]);

  // update the overlay's position if the given coordinates change
  useEffect(() => {
    if (!overlay) return;

    if (props.coordinate) {
      overlay.setPosition(props.coordinate);
    }
  }, [overlay, props.coordinate]);

  return (
    <div ref={targetRef} className={styles.featuresOverlay}>
      {props.features?.map((feature) => (
        <FeatureCard feature={feature} />
      ))}
    </div>
  );
};

export default FeaturesOverlay;
