import { useAppSelector } from "../store/hooks";
import { useParams } from "react-router";

import TwoColumnLayout from "../components/design/TwoColumnLayout";
import ContentDetail from "../components/content/ContentDetail";

const DetailsPage = () => {
  const recentContent = useAppSelector((state) => state.content.recent);
  const params = useParams();

  if (!params.contentId) {
    return <div>Not Found!</div>;
  }

  const intContentId = parseInt(params.contentId);

  // FIXME: should not rely on recent content
  const content = recentContent.find((c) => c.id === intContentId);

  if (!content) {
    return <div>Not Found!</div>;
  }

  return (
    <TwoColumnLayout>
      {params.contentId && (
        <ContentDetail content={content} isTitleClickable={false} />
      )}
    </TwoColumnLayout>
  );
};

export default DetailsPage;
