import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useAppDispatch } from "./store/hooks";
import { fetchRecentComments } from "./store/comments";
import { fetchSponsors } from "./store/sponsors";
import "bootstrap/dist/css/bootstrap.min.css";

import OverviewPage from "./pages/OverviewPage";
import DetailsPage from "./pages/DetailsPage";
import GalleryPage from "./pages/GalleryPage";
import ImageViewerPage from "./pages/ImageViewerPage";
import ImageViewerFullScreenPage from "./pages/ImageViewerFullScreenPage";
import RouteSelectPage from "./pages/RouteSelectPage";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchRecentComments());
    dispatch(fetchSponsors());
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={<OverviewPage />} />
      <Route path="/content">
        <Route index element={<OverviewPage />} />
        <Route path=":contentId" element={<DetailsPage />} />
      </Route>
      <Route path="/gallery" element={<GalleryPage />} />
      <Route path="/imageviewer" element={<ImageViewerPage />} />
      <Route
        path="/imageviewer/full-screen"
        element={<ImageViewerFullScreenPage />}
      />
      <Route path="/maps" element={<RouteSelectPage />} />
    </Routes>
  );
}

export default App;
