import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import contentReducer from "./content";
import commentsReducer from "./comments";
import sponsorsReducer from "./sponsors";

export const store = configureStore({
  reducer: {
    content: contentReducer,
    comments: commentsReducer,
    sponsors: sponsorsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
