import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import BNavbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import styles from "./Menu.module.css";

const Menu = () => (
  <BNavbar>
    <Container fluid>
      <BNavbar.Brand as="div">
        <div className={styles.brand}>OudOmmen.nl</div>
      </BNavbar.Brand>
      <BNavbar.Toggle aria-controls="main-navbar" />
      <BNavbar.Collapse id="main-navbar">
        <Nav>
          <Nav.Link as="div">
            <Link className={styles.link} to="/">
              Home
            </Link>
          </Nav.Link>
          <Nav.Link as="div">
            <Link className={styles.link} to="/gallery">
              Beeldbank
            </Link>
          </Nav.Link>
          <Nav.Link as="div">
            <Link className={styles.link} to="/imageviewer">
              ImageViewer
            </Link>
          </Nav.Link>
          <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item as="div">First</NavDropdown.Item>
            <NavDropdown.Item as="div">Second</NavDropdown.Item>
            <NavDropdown.Item as="div">Thirth</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item as="div">Fourth</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </BNavbar.Collapse>
    </Container>
  </BNavbar>
);

export default Menu;
