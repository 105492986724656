import React from "react";
import styles from "./Widget.module.css";

interface WidgetProps {
  title: string;
  children: React.ReactNode;
}

const Widget = (props: WidgetProps) => (
  <div className={styles.widget}>
    <div className={styles.title}>{props.title}</div>
    <div className={styles.content}>{props.children}</div>
  </div>
);

export default Widget;
