import React from "react";
import Geolocation from "ol/Geolocation";
import OpenLayersMap from "ol/Map";

interface MapContextInterface {
  map?: OpenLayersMap;
  geolocation?: Geolocation;
}

const MapContext = React.createContext<MapContextInterface>({
  map: undefined,
  geolocation: undefined,
});

export default MapContext;
