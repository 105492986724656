import SponsorsBar from "../components/design/SponsorsBar";
import ImageViewer from "../components/beeldbank/ImageViewer";
import TwoColumnLayout from "../components/design/TwoColumnLayout";

const ImageViewerPage = () => {
  return (
    <TwoColumnLayout>
      <SponsorsBar />
      <ImageViewer />
    </TwoColumnLayout>
  );
};

export default ImageViewerPage;
