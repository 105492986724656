import { useContext, useEffect } from "react";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import MapContext from "../Map/MapContext";

const OSMTileLayer = () => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const layer = new TileLayer({
      source: new OSM(),
      zIndex: 0,
    });

    map.addLayer(layer);

    return () => {
      map.removeLayer(layer);
    };
  }, [map]);

  return null;
};

export default OSMTileLayer;
