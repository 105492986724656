import CommentsWidget from "../content/CommentsWidget";
import SponsorsWidget from "./SponsorsWidget";

const DefaultWidgets = () => (
  <>
    <CommentsWidget />
    <SponsorsWidget />
  </>
);

export default DefaultWidgets;
