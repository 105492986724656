import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import OpenSeadragon from "openseadragon";

interface ImageViewerProps {
  url?: string;
  fullScreen?: boolean;
  width?: string;
  height?: string;
}

const ImageViewer = (props: ImageViewerProps) => {
  const el = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (el.current) {
      // clear old instance in case of `componentDidUpdate`
      el.current.innerHTML = "";

      // get tile URL from props or query parameters
      const tileUrl = props.url ? props.url : searchParams.get("url");

      if (tileUrl) {
        // initialize OpenSeadragon
        OpenSeadragon({
          id: el.current.id,
          tileSources: {
            type: "image",
            url: tileUrl,
          },
          prefixUrl: "/openseadragon-images/",
          maxZoomPixelRatio: 2,
          showNavigator: true,
        });
      }
    }
  });

  const style = props.fullScreen
    ? { width: "100vw", height: "100vh" }
    : { width: props.width || "100%", height: props.height || "900px" };

  return <div id="openseadragon" style={style} ref={el}></div>;
};

export default ImageViewer;
