import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Header from "./Header";
import DefaultWidgets from "../design/DefaultWidgets";
import Menu from "./Menu";

interface TwoColumnLayoutProps {
  widgets?: React.ReactNode;
  children?: React.ReactNode;
}

const TwoColumnLayout = (props: TwoColumnLayoutProps) => (
  <>
    <Header />
    <Menu />
    <Container fluid>
      <Row>
        <Col
          xs={{ span: 12, order: "last" }}
          lg={{ span: 3, order: "first" }}
          xl={{ span: 2, order: "first" }}
        >
          {props.widgets || <DefaultWidgets />}
        </Col>
        <Col
          xs={{ span: 12, order: "first" }}
          lg={{ span: 9, order: "last" }}
          xl={{ span: 10, order: "last" }}
        >
          {props.children}
        </Col>
      </Row>
    </Container>
  </>
);

export default TwoColumnLayout;
