import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Comment } from "./types";

interface CommentsState {
  recent: Comment[];
}

const initialState: CommentsState = {
  recent: [],
};

const fetchRecentComments = createAsyncThunk(
  "comments/fetchRecent",
  async () => {
    // const response = await client.get("/comments/");
    // return response.data.results;
    return [];
  }
);

export const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    clear(state, action) {
      state.recent = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecentComments.fulfilled, (state, action) => {
      state.recent = action.payload;
    });
  },
});

export { fetchRecentComments };
export const { clear } = commentsSlice.actions;

export default commentsSlice.reducer;
