import React from "react";
import Stack from "react-bootstrap/Stack";
import Widget from "../design/Widget";
import { useAppSelector } from "../../store/hooks";
import { formatDate } from "../../utils/date";
import styles from "./CommentsWidget.module.css";

interface CommentsWidgetItemProps {
  source: string;
  author: string;
  dateAndTime: string;
  contentTitle: string;
  children: React.ReactNode;
}

const CommentsWidgetItem = (props: CommentsWidgetItemProps) => (
  <Stack direction="vertical">
    <Stack direction="horizontal" gap={3}>
      <div className={`${styles.meta} ${styles.source}`}>{props.source}</div>
      <div className={`${styles.meta} ${styles.author}`}>{props.author}</div>
      <div className={`${styles.meta} ${styles.dateAndTime}`}>
        {formatDate(props.dateAndTime)}
      </div>
    </Stack>
    <div className={styles.contentTitle}>{props.contentTitle}</div>
    <div className={styles.comment}>{props.children}</div>
  </Stack>
);

const CommentsWidget = () => {
  const comments = useAppSelector((state) => state.comments.recent);

  return (
    <Widget title="Comments">
      {comments.map((comment: any) => (
        <CommentsWidgetItem
          source={comment.source}
          author={comment.author_name}
          dateAndTime={comment.date_and_time}
          contentTitle={comment.content.title}
        >
          {comment.text}
        </CommentsWidgetItem>
      ))}
    </Widget>
  );
};

export default CommentsWidget;
