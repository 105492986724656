import React from "react";

interface LinkProps {
  url: string;
  children: React.ReactNode;
  [other: string]: any;
}

const Link = (props: LinkProps) => (
  <a href={props.url} {...props.other}>
    {props.children}
  </a>
);

export default Link;
