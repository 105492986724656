import React, { useContext, useEffect } from "react";
import Feature from "ol/Feature";
import Icon from "ol/style/Icon";
import Point from "ol/geom/Point";
import Style from "ol/style/Style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { fromLonLat } from "ol/proj";
import MapContext from "../Map/MapContext";
import markerIcon from "../../../assets/img/marker-oudommen.png";

interface MarkerLayerProps {
  children: React.ReactElement | React.ReactElement[];
}

const MarkerLayer = (props: MarkerLayerProps) => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map || !props.children) return;

    const features = React.Children.map(props.children, (marker) => {
      if ((marker.props.lat, marker.props.lon)) {
        const lonLat = [marker.props.lon, marker.props.lat];

        return new Feature({
          geometry: new Point(fromLonLat(lonLat)),
        });
      }
    });

    const layer = new VectorLayer({
      source: new VectorSource({ features: features }),
      style: new Style({
        image: new Icon({
          src: markerIcon,
          anchor: [0.5, 1],
        }),
      }),
      zIndex: 2,
    });

    map.addLayer(layer);

    return () => {
      map.removeLayer(layer);
    };
  }, [map, props.children]);

  return null;
};

export default MarkerLayer;
