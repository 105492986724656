import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Sponsor } from "./types";

type SponsorsState = {
  items: Sponsor[];
};

const initialState: SponsorsState = {
  items: [],
};

const fetchSponsors = createAsyncThunk("sponsors/fetch", async () => {
  // const response = await client.get("/sponsors/");
  // return response.data.results;
  return [];
});

export const sponsorsSlice = createSlice({
  name: "sponsors",
  initialState,
  reducers: {
    clear(state, action) {
      state.items = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSponsors.fulfilled, (state, action) => {
      state.items = action.payload;
    });
  },
});

export { fetchSponsors };
export const { clear } = sponsorsSlice.actions;

export default sponsorsSlice.reducer;
