import { Link } from "react-router-dom";
import { Content } from "../../store/content/types";
import { formatDate } from "../../utils/date";
import styles from "./ContentDetail.module.css";

interface ContentDetailProps {
  content: Content;
  isTitleClickable: boolean;
}

const ContentDetail = (props: ContentDetailProps) => {
  return (
    <div className={styles.ContentListItem}>
      <div className={styles.title}>
        {props.isTitleClickable ? (
          <Link to={`/content/${props.content.id}`}>{props.content.title}</Link>
        ) : (
          props.content.title
        )}
      </div>
      <div className={styles.meta}>
        {formatDate(props.content.date_and_time)}
      </div>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: props.content.text }}
      ></div>
    </div>
  );
};

export default ContentDetail;
