import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Content } from "./types";

interface ContentState {
  recent: Content[];
}

const initialState: ContentState = {
  recent: [],
};

const fetchRecentContent = createAsyncThunk("content/fetchRecent", async () => {
  // const response = await client.get("/content/");
  // return response.data.results;
  return [];
});

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    clear(state, action) {
      state.recent = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRecentContent.fulfilled, (state, action) => {
      state.recent = action.payload;
    });
  },
});

export { fetchRecentContent };
export const { clear } = contentSlice.actions;

export default contentSlice.reducer;
