import { useState } from "react";
import { fromLonLat } from "ol/proj";
import Map from "../components/map/Map/Map";
import Layers from "../components/map/Layers/Layers";
import OSMTileLayer from "../components/map/Layers/OSMTileLayer";
import GeolocationLayer from "../components/map/Layers/GeolocationLayer";
import KMLLayer from "../components/map/Layers/KMLLayer";
import MarkerLayer from "../components/map/Layers/MarkerLayer";
import Controls from "../components/map/Controls/Controls";
import FullScreen from "../components/map/Controls/FullScreen";
import Marker from "../components/map/Markers/Marker";

const RouteSelectPage = () => {
  const [center] = useState([6.099, 52.518]);
  const [zoom] = useState(18);

  return (
    <Map center={fromLonLat(center)} zoom={zoom}>
      <Layers>
        <OSMTileLayer />
        <GeolocationLayer />
        <KMLLayer url="https://www.oudommen.nl/media/gpx_kml/Kamp-Erika-nieuw204.kml" />
        <MarkerLayer>
          <Marker lat={52.51838} lon={6.09926} />
          <Marker lat={52.51837} lon={6.09998} />
        </MarkerLayer>
      </Layers>
      <Controls>
        <FullScreen />
      </Controls>
    </Map>
  );
};

export default RouteSelectPage;
