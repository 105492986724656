import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import noImage from "../../assets/img/no-image.svg";

interface GalleryItem {
  id: number;
  title: string;
}

interface GalleryProps {
  items: GalleryItem[];
}

const Gallery = (props: GalleryProps) => {
  return (
    <Row className="row-cols-4">
      {props.items.map((item) => (
        <Col>
          <Card key={item.id}>
            <Card.Img variant="top" src={noImage} />
            <Card.Body>
              <Card.Title>{item.title}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Gallery;
