interface MarkerProps {
  lat: number;
  lon: number;
}

const Marker = (props: MarkerProps) => {
  return null;
};

export default Marker;
