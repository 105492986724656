import { useEffect, useState } from "react";
import TwoColumnLayout from "../components/design/TwoColumnLayout";
import SponsorsBar from "../components/design/SponsorsBar";
import Gallery from "../components/beeldbank/Gallery";

const GalleryPage = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // const response = await client.get("/content/");
      setItems([]);
    };

    fetchData();
  }, []);

  return (
    <TwoColumnLayout>
      <SponsorsBar />
      <Gallery items={items} />
    </TwoColumnLayout>
  );
};

export default GalleryPage;
