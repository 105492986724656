import { useContext, useEffect } from "react";
import Feature from "ol/Feature";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import MapContext from "../Map/MapContext";
import { Point } from "ol/geom";

const GeolocationLayer = () => {
  const { map, geolocation } = useContext(MapContext);

  useEffect(() => {
    if (!map || !geolocation) return;

    const positionFeature = new Feature();
    const accuracyFeature = new Feature();

    const layer = new VectorLayer({
      source: new VectorSource({
        features: [positionFeature, accuracyFeature],
      }),
      zIndex: 1,
    });

    const changePositionListener = () => {
      const position = geolocation?.getPosition();
      positionFeature.setGeometry(position ? new Point(position) : undefined);
    };

    const changeAccuracyGeometryListener = () => {
      const accuracyGeometry = geolocation?.getAccuracyGeometry();
      accuracyFeature.setGeometry(
        accuracyGeometry ? accuracyGeometry : undefined
      );
    };

    map.addLayer(layer);
    geolocation.on("change:position", changePositionListener);
    geolocation.on("change:accuracyGeometry", changeAccuracyGeometryListener);

    return () => {
      geolocation.un("change:accuracyGeometry", changeAccuracyGeometryListener);
      geolocation.un("change:position", changePositionListener);
      map.removeLayer(layer);
    };
  }, [map, geolocation]);

  return null;
};

export default GeolocationLayer;
