import { useContext, useEffect } from "react";
import OpenLayersFullScreen from "ol/control/FullScreen";
import MapContext from "../Map/MapContext";

const FullScreen = () => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const control = new OpenLayersFullScreen();

    map.addControl(control);

    return () => {
      map.removeControl(control);
    };
  }, [map]);

  return null;
};

export default FullScreen;
