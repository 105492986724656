import TwoColumnLayout from "../components/design/TwoColumnLayout";
import SponsorsBar from "../components/design/SponsorsBar";
import ContentList from "../components/content/ContentList";

const OverviewPage = () => {
  return (
    <TwoColumnLayout>
      <SponsorsBar />
      <ContentList />
    </TwoColumnLayout>
  );
};

export default OverviewPage;
