import { useState, useEffect } from "react";
import { fetchRecentContent } from "../../store/content";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import ContentDetail from "./ContentDetail";

const ContentList = () => {
  const items = useAppSelector((state) => state.content.recent);
  const dispatch = useAppDispatch();

  const [isRefreshRequested, setIsRefreshRequested] = useState(true);

  useEffect(() => {
    if (isRefreshRequested) {
      setIsRefreshRequested(false);
      dispatch(fetchRecentContent());
    }
  }, [isRefreshRequested, dispatch]);

  return (
    <>
      {items.map((item) => (
        <ContentDetail content={item} isTitleClickable={true} />
      ))}
    </>
  );
};

export default ContentList;
