import { useCallback, useEffect, useState } from "react";
import Geolocation from "ol/Geolocation";
import OpenLayersMap from "ol/Map";
import OpenLayersView from "ol/View";
import { Coordinate } from "ol/coordinate";
import { defaults as defaultInteractions } from "ol/interaction";
import MapContext from "./MapContext";
import styles from "./Map.module.css";

interface MapProps {
  center: Coordinate;
  zoom: number;
  children: React.ReactNode;
}

const Map = (props: MapProps) => {
  const [map, setMap] = useState<OpenLayersMap | undefined>(undefined);
  const [geolocation, setGeolocation] = useState<Geolocation | undefined>(
    undefined
  );

  // initialize the map once the DOM is ready
  const targetRef = useCallback((node: any) => {
    if (!node) return;

    setMap(
      new OpenLayersMap({
        controls: [],
        interactions: defaultInteractions(),
        layers: [],
        overlays: [],
        target: node,
        view: new OpenLayersView(),
      })
    );
  }, []);

  // start geolocation tracking once the map is ready
  useEffect(() => {
    if (!map) {
      setGeolocation(undefined);
      return;
    }

    setGeolocation(
      new Geolocation({
        tracking: true,
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: map.getView().getProjection(),
      })
    );
  }, [map]);

  // update center if property changes
  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(props.center);
  }, [map, props.center]);

  // update zoom if property changes
  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(props.zoom);
  }, [map, props.zoom]);

  // both `map` and `geolocation` state are passed to child elements using MapContext
  return (
    <MapContext.Provider value={{ map, geolocation }}>
      <div ref={targetRef} className={styles["ol-map"]}>
        {props.children}
      </div>
    </MapContext.Provider>
  );
};

export default Map;
